import { ethers } from 'ethers';
import { Seaport } from '@opensea/seaport-js';

declare var window: any;

export const makeNFTOffer = async (
  isConnected: boolean,
  tokenId: any,
  assetContractAddress: any,
  offerPrice: any,
  endDate: any
): Promise<string> => {
  if (!isConnected) throw new Error("You must be connected to make an offer.");
  if (!tokenId || !assetContractAddress) throw new Error("NFT details are incomplete.");

  const wethAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'; // WETH ERC-720 native token address
  const protocolAddress = '0x0000000000000068f116a894984e2db1123eb395';
  const openSeaFeeAddress = '0x0000a26b00c1F0DF003000390027140000fAa719';
  const zoneValue = '0x000056F7000000EcE9003ca63978907a00FFD100';
  const zoneHashValue = '0x0000000000000000000000000000000000000000000000000000000000000000';

  const generateSalt = (): string => {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return '0x' + Array.from(array).map(b => b.toString(16).padStart(2, '0')).join('');
  };

  const offerAmount = ethers.parseEther(offerPrice);
  const marketplaceFeeBasisPoints = 50;
  const marketplaceFee = offerAmount * BigInt(marketplaceFeeBasisPoints) / BigInt(10000);

  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const offerer = await signer.getAddress();
  const seaport = new Seaport(signer, 
    { balanceAndApprovalChecksOnOrderCreation: true, overrides: {seaportVersion: '1.6' }});

  const asset = {
    tokenId: tokenId,
    tokenAddress: assetContractAddress,
  };

 let new_parameters = {
    orderType: 3,
    offerer: offerer,
    offer: [
      {
        itemType: 1,
        identifierOrCriteria: 0,
        startAmount: offerAmount.toString(),
        endAmount: offerAmount.toString(),
        token: wethAddress
      }
    ],
    consideration: [
      {
        itemType: 3,
        identifierOrCriteria: asset.tokenId,
        token: asset.tokenAddress,
        startAmount: 1,
        endAmount: 1,
        recipient: offerer,
      },
      {
        itemType: 1,
        token: wethAddress,
        identifierOrCriteria: 0,
        startAmount: marketplaceFee.toString(),
        endAmount: marketplaceFee.toString(),
        recipient: openSeaFeeAddress,
      }
    ],
    startTime: Math.floor(new Date().getTime() / 1000).toString(),
    endTime: Math.floor(new Date(endDate).getTime() / 1000),
    zone: zoneValue,
    zoneHash: zoneHashValue,
    salt: generateSalt(),
    totalOriginalConsiderationItems: 2,
    counter: 0,
    conduitKey: seaport.OPENSEA_CONDUIT_KEY,
  };

  const getSign = async (parameters: any): Promise<any> => {
    try {
      const signature = await seaport.signOrder(parameters);
      return {
        parameters: parameters,
        signature: signature,
      };
    } catch (error) {
      return "Failed to sign offer. Please try again.";
    }
  };

  const sendToApi = async (order: any): Promise<string> => {
    try {
      order["protocol_address"] = protocolAddress;
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          'X-API-KEY': `${process.env.REACT_APP_OPENSEA_API_KEY}`,
        },
        body: JSON.stringify(order),
      };

      const resp = await fetch('https://api.opensea.io/api/v2/orders/ethereum/seaport/offers', options);
      if (!resp.ok) {
        throw new Error(`API request failed with status ${resp.status}`);
      }

      return "Offer submitted successfully!";
    } catch (error) {
      return "Failed to submit offer. Please try again.";
    }
  };

  const signed = await getSign(new_parameters);
  if (typeof signed === 'string') return signed;
  return await sendToApi(signed);
};